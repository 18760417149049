/*
 *************************************************************************************************
 * 01. 업무구분 : 입고검사 > 입고검사 조회 결과
 * 02. 프로그램ID : QmsMIncmInspListResult.tsx
 * 03. 프로그램명 : 검사대상 및 결과입력 리스트
 * 04. 화면설명 : 입고검사 검사대상
 * 05. 작성일 : 2023.08.24
 * 06. 작성자 : 채보람
 **************************************************************************************************
 *     수정일        이  름    사유
 **************************************************************************************************
 *     2023-08-24     채보람    최초 작성
 **************************************************************************************************
 */

import React, {useEffect, useState} from 'react';
import useAxios, {AXIOS_HEADER} from '../../hooks/useAxios';
import {
    CardViewType,
    ComponentHistoryType,
    NexacroJsonType,
    NexacroJsonUnitType
} from '../../components/model/SqciTypes';
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {useDataset} from "../../components/contexts/CommonContext";
import {toNumber} from "lodash";
import usePageMove from "../../hooks/usePageMove";
import useGlobalData from "../../hooks/useGlobalData";
import {STORAGE_NAME} from "../../components/CommonConstants";
import {gfn_isNull} from "../../components/utils/CommonUtils";
import {useComponent} from "../../components/contexts/ComponentContext";
import {CSSTransition} from "react-transition-group";
import QmsTranLineMeas from "./QmsTranLineMeas";
import QmsInspGroup from "./QmsInspGroup";
import InfiniteCardView from "../common/InfiniteCardView";
import * as gfn from "../../components/utils/CommonUtils";
import {useError} from "../../hooks/useException";
import {v4 as uuidv4} from "uuid";

export default function QmsMIncmInspListResult() {
    /* react, 공통 변수 선언 */
    const {sendAxios} = useAxios();
    const navigate = useNavigate();
    const {state} = useLocation();
    const {dataset, sessionValues} = useDataset();
    const {pageMove} = usePageMove();
    const {getGlobalSetting, setGlobalSetting, getDomain, getMessage} = useGlobalData();
    const sLocale = getGlobalSetting<string>(STORAGE_NAME.LANGUAGE);
    const {setDialog} = useComponent();
    const [showSwiperLineMeas, setShowSwiperLineMeas] = useState<boolean>(false);
    const [showSwiperInspGroup, setShowSwiperInspGroup] = useState<boolean>(false);
    const {throwException} = useError();

    /* 로직 변수 선언 */
    let [ds_List, setDs_List] = useState(new Array<any>());
    const [cardData, setCardData] = useState(new Array<any>());
    const [selectedCardDataIdx, setSelectedCardDataIdx] = useState(-1);
    const [secAnlResult, setSecAnlResult] = useState(String);
    let fv_InspResultNo = "";
    let fv_Qty = 0; //  검사할 수량
    let fv_qmvFlag = ""; // 출하성적서 열기 플래그
    let fv_saveListFlag = "N"; // 목록 판정 플래그
    let fv_groupFlag = "N"; // 그룹 판정 플래그
    let fv_refreshFlag = "N"; // 화면 리프레시 할건지 플래그
    let fv_isChangeSpecFlag = false; // 임시저장된 데이터의 Spec 변경 여부 변수 선언
    // Eco.XComp.setUserProperty(this.grd_List, "griduserproperty", "personal,colhide");/** VOC 81 Start 2019.05.22 남경진 그리드 컬럼 설정 값 변경 및 저장기능 추가 **/
    // let fv_isDivisionQ = nexacro.getApplication().gds_user.getColumn(0, "DIVISION_CD") == "Q" || this.gfn_checkAuth(7) ? true : false; // 무선사업부 여부 변수 선언
    /*const [ds_Search, setDs_Search] = useState(state.ds_Search);
    const [ds_Aql, setDs_Aql] = useState(new Array<NexacroJsonUnitType>());
    const [ds_AllSample, setDs_AllSample] = useState(new Array<NexacroJsonUnitType>());
    const [ds_popChngPoingHList, setDs_popChngPoingHList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_popChngPoingHListFiltered, setDs_popChngPoingHListFiltered] = useState(new Array<NexacroJsonUnitType>());
    const [ds_BaseLine, setDs_BaseLine] = useState(new Array<NexacroJsonUnitType>());
    const [ds_FailCaseList, setDs_FailCaseList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Group, setDs_Group] = useState(new Array<NexacroJsonUnitType>());
    const [ds_GroupList, setDs_GroupList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_ListSelect, setDs_ListSelect] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Spec, setDs_Spec] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Defect, setDs_Defect] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Sample, setDs_Sample] = useState(new Array<NexacroJsonUnitType>());
    const [ds_searchSpecApproveStatus, setDs_searchSpecApproveStatus] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SpecApproveStatus, setDs_SpecApproveStatus] = useState(new Array<NexacroJsonUnitType>());
    const [ds_IssuePartList, setDs_IssuePartList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SubmitInspList, setDs_SubmitInspList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SearchInspDetail, setDs_SearchInspDetail] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SearchSpec, setDs_SearchSpec] = useState(new Array<NexacroJsonUnitType>());
    const [ds_InspDetail, setDs_InspDetail] = useState(new Array<NexacroJsonUnitType>());*/

    const [ds_Aql, setDs_Aql] = useState(new Array<NexacroJsonUnitType>());
    const [ds_AllSample, setDs_AllSample] = useState(new Array<NexacroJsonUnitType>());
    const [ds_IssuePartList, setDs_IssuePartList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_SubmitInspList, setDs_SubmitInspList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_chngPoingHList, setDs_chngPoingHList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Defect, setDs_Defect] = useState(new Array<NexacroJsonUnitType>());
    const [ds_FailCaseList, setDs_FailCaseList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_InspDetailList, setDs_InspDetailList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_ListLineMeas, setDs_ListLineMeas] = useState(new Array<NexacroJsonUnitType>());
    const [ds_ListSelect, setDs_ListSelect] = useState(new Array<NexacroJsonUnitType>());
    const [ds_GroupList, setDs_GroupList] = useState(new Array<NexacroJsonUnitType>());
    const [ds_Group, setDs_Group] = useState(new Array<NexacroJsonUnitType>());
    const [lineSaveDisabled, setLineSaveDisabled] = useState(false);
    // const [seed, setSeed] = useState(1);

    let vds_Search = new Array<NexacroJsonUnitType>(state.requestJson.ds_Search);
    let vds_Aql = new Array<NexacroJsonUnitType>();
    let vds_AllSample = new Array<NexacroJsonUnitType>();
    let vds_popChngPoingHList = new Array<NexacroJsonUnitType>();
    let vds_popChngPoingHListFiltered = new Array<NexacroJsonUnitType>();
    let vds_BaseLine = new Array<NexacroJsonUnitType>();
    let vds_FailCaseList = new Array<NexacroJsonUnitType>();
    let vds_Group: any = [];
    let vds_GroupList = new Array<NexacroJsonUnitType>();
    let vds_ListSelect = new Array<NexacroJsonUnitType>();
    let vds_Spec = new Array<NexacroJsonUnitType>();
    let vds_Defect = new Array<NexacroJsonUnitType>();
    let vds_Sample = new Array<NexacroJsonUnitType>();
    let vds_searchSpecApproveStatus = new Array<NexacroJsonUnitType>();
    let vds_SpecApproveStatus = new Array<NexacroJsonUnitType>();
    let vds_IssuePartList = new Array<NexacroJsonUnitType>();
    let vds_SubmitInspList = new Array<NexacroJsonUnitType>();
    let vds_SearchInspDetail = new Array<NexacroJsonUnitType>();
    let vds_SearchSpec = new Array<NexacroJsonUnitType>();
    let vds_InspDetail = new Array<NexacroJsonUnitType>();

    let data: CardViewType[] = [];
    const [infiniteScroll, setInfiniteScroll]= useState<CardViewType[]>([]);


    // return (
    //     <>
    //         <button onClick={() => navigate(-1)} >뒤로가기</button>
    //         {
    //             sendAxios('/common/nexacro/QMS_M_Incm_InspListDataSelect.do', 'POST', AXIOS_HEADER.NEXACRO, state.requestJson)
    //             .then((response) => {
    //                 // navigate(process.env.REACT_APP_URL_QMS_RST || '/', {state: response?.data.ds_List});
    //                 console.log(response?.data.ds_List);
    //             }).catch(e => {
    //                 throwException(e);
    //                 return new Promise(() => {
    //                 });
    //             });
    //         }
    //     </>
    // )

    const location = useLocation();
    const {enteredComponent, exitedComponent} = useOutletContext<ComponentHistoryType>();
    const [isComponentLoaded, setIsComponentLoaded] = useState<boolean>(false);
    useEffect(() => {
        if((location.pathname === enteredComponent) && (location.pathname !== exitedComponent)) {
            setIsComponentLoaded(true);

            form_onload();
        }
    }, [enteredComponent, exitedComponent]);

    /* 화면 onload */
    function form_onload() {
        // AQL, SAMPLE CNT 테이블 조회
        fn_selectAqlCnt(); // AQL 테이블 조회
    }

    /* AQL 테이블 조회 */
    function fn_selectAqlCnt() {
        sendAxios('/common/nexacro/QMS_P_Pre_SelectAqlCnt.do', 'POST', AXIOS_HEADER.NEXACRO, '', true)
        .then((response) => {
            vds_Aql = response?.data.ds_Aql;
            vds_AllSample = response?.data.ds_AllSample;

            setDs_Aql(response?.data.ds_Aql);
            setDs_AllSample(response?.data.ds_AllSample);
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* ECN 변경점 조회 */
    function fn_EcnSearch(divCd: string, plantCd: string, hqCd: string, partCd: string) {
        const requestJson: NexacroJsonType = {
            ds_search: [{
                LANG: sLocale,
                FROM_DATE: getToday(0, 0, -1),
                TO_DATE: getToday(),
                VENDOR_CD: hqCd,
                MATERIAL_CD: partCd,
                TYPE: "01",
                // STATUS_CD: , // 나중에 필터함
                RECORDS: "99999999",
                RECORDSOFFSET: "0",
                SEARCH_TYPE: "1"
            }],
            ds_plant: [{
                PLANT_CD: plantCd
            }],
            ds_division: [{
                DIVISION_CD: divCd
            }]
        };

        sendAxios('/common/nexacro/selectTotalStatusList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            setDs_chngPoingHList(response?.data.ds_chngPoingHList);
            vds_popChngPoingHListFiltered = (response?.data.ds_chngPoingHList || []).filter((v:NexacroJsonType) => String(v.STATUS) == "1" || String(v.STATUS) == "2" || String(v.STATUS) == "5");

            fn_selectBaseLine(divCd, ds_List[selectedCardDataIdx]['CATEGORY2']); // 검사기준서 조회
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* 검사기준서 조회 */
    function fn_selectBaseLine(divCd: string, category2: string) {
        const requestJson: NexacroJsonType = {
            ds_SearchBaseLine: [{
                DIVISION_CD: divCd,
                CATEGORY2: category2
            }]
        };

        sendAxios('/common/nexacro/selectInspBaseLine.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            vds_BaseLine = response?.data.ds_BaseLine;

            fn_SearchIssuePartForDetail(); // 이슈자재 조회
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* 이슈자재 조회 */
    function fn_SearchIssuePartForDetail() {
        const requestJson: NexacroJsonType = {
            ds_SearchIssue: [{
                DIVISION_CD: ds_List[selectedCardDataIdx]['DIVISION_CD'],
                LANG: sLocale,
                PLANT_CD: ds_List[selectedCardDataIdx]['PLANT_CD'],
                PART_CD: ds_List[selectedCardDataIdx]['PART_CD'],
                HQ_CD: ds_List[selectedCardDataIdx]['HQ_CD'],
                FROM_DATE: getToday(0, 0, -1),
                TO_DATE: getToday(),
                IS_QMS_INSP: "Y",
            }]
        };

        sendAxios('/common/nexacro/QMS_M_Incm_FailCaseDataSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            setDs_FailCaseList(response?.data.ds_FailCaseList);
            vds_FailCaseList = response?.data.ds_FailCaseList;

            fn_IncmInspRegOpen(selectedCardDataIdx); // 입고검사 성적서 작성 팝업
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* 입고검사대상 및 결과입력 목록 조회 */
    function fn_tranSearch() {
        // setSeed(Math.random());
        // window.location.reload();
        /*sendAxios('/common/nexacro/QMS_M_Incm_InspListDataSelect.do', 'POST', AXIOS_HEADER.NEXACRO, state.requestJson)
            .then((response) => {
                // navigate(process.env.REACT_APP_URL_QMS_RST || '/', {state: response?.data.ds_List});
                // console.log(response?.data.ds_List);
                setDs_List(response?.data.ds_List);
                setDs_InspDetailList(response?.data.ds_List);

                for (let i = 0; i < response?.data.ds_List.length; i++) {
                    let tempList = response?.data.ds_List[i];

                    let tempData: CardViewType = {
                        id: String(i),
                        label: [{
                            name: tempList.IV_STATUS_NM,
                            style: 'label-type1'
                        }, {
                            name: tempList.NEW_PART_TYPE_NM,
                            style: 'label-type3'
                        }, {
                            name: tempList.SUB_STATUS_NM,
                            style: 'label-type2'
                        }],
                        title: {
                            category: "[" + tempList.PLANT_CD + "] [" + tempList.HQ_CD + "] [" + tempList.PART_CD + "]",
                            name: tempList.PART_NM
                        },
                        state: [{
                            left: 'I/V No : ' + tempList.INV_NO,
                            right: tempList.SUP_RECEIVE_DATE
                        }, {
                            left: 'Insp No : ' + tempList.SEC_INSP_ID,
                            right: tempList.SEC_INSP_DT
                        }, {
                            left: getDomain('DOMAIN2415') + ' : ' + tempList.GR_QTY,
                            right: tempList.INSP_USER
                        }]
                    };

                    data.push(tempData);
                }

                setCardData(data);
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });*/
    }

    /* 임시저장된 입고성적서와 변경된 출하성적서 자재구분 매칭 */
    function fn_selectIncmDetail() {
        const requestJson: NexacroJsonType = {
            ds_SearchInspDetail: vds_SearchInspDetail
        };

        // 더블 클릭시 데이터 세팅
        return sendAxios('/common/nexacro/QMS_M_Incm_InspDetailData.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    /* 그룹판정 한개 로우 선택 */
    function fn_saveGroup() {
        let row = selectedCardDataIdx;

        if (row == -1) {
            return;
        }

        if (ds_List[row]["TSI_AGENT_YN"] == "Y") {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01387"),
                confirm: () => {
                }
            });

            return;
        }

        //그룹판정
        let isGroup = fn_checkGroup(row);

        if (isGroup == "SUCCESS") {
            vds_ListSelect = ds_List[row];

            fn_openInspGroupListPopup();
        } else {
            setDialog({
                type: 'ALERT',
                show: true,
                text: isGroup,
                confirm: () => {
                }
            });
        }
    }

    /* 그룹판정 선택 조건 */
    function fn_checkGroup(row: number) {
        //동일 출하 LOT 기준(성적서 개수 1, 성적서 제출 O)
        if (ds_List[row]["GROUP_YN"] == 'Y') {
            return getMessage("MSG01267");
        }

        //입고 여부
        if (ds_List[row]["GR_DATE"] == null || ds_List[row]["GR_DATE"] == "") {
            return getMessage("MSG01262");
        }

        //검사대상여부
        if (ds_List[row]["INSP_STATUS"] == "N") {
            return getMessage("MSG01044");
        }

        if (ds_List[row]["SUB_STATUS"] != 'Y' || parseInt(ds_List[row]["SUBMIT_CNT"]) != 1
            || ds_List[row]["INSP_STATUS"] != "Y" ) {

            return getMessage("MSG01261");
        }

        return "SUCCESS";
    }

    /* 그룹판정 팝업 호출 */
    function fn_openInspGroupListPopup() {
        // let param = new Map();
        // param.set("pv_data", vds_ListSelect);

        goShowSwiperInspGroup(selectedCardDataIdx);
    }

    /* 그룹 판정 선택 후 팝업 호출 */
    function fn_openInspGroupPopup() {
        //그룹데이터 초기화
        fn_setGroupData();

        fv_Qty = Number(vds_Group[0]["GR_QTY"]);

        if (vds_SearchSpec.length == 0) {
            vds_SearchSpec.push({
                LOT_SIZE: vds_Group[0]["GR_QTY"],
            });
        } else {
            vds_SearchSpec[0]["LOT_SIZE"] = vds_Group[0]["GR_QTY"];
        }

        /** VOC 03 Start 2019.12.02 김형준 - Spec 정보 조회시 Plant_Cd 값 Setting  **/
        vds_SearchSpec[0]["PLANT_CD"] = vds_Group[0]["PLANT_CD"];
        /** VOC 03 End 2019.12.02 김형준 - Spec 정보 조회시 Plant_Cd 값 Setting  **/

        //IPC 의 경우에는 협력사 바꾸어서 조회
        if (vds_Group[0]["INSP_DIV"] == "IS" || vds_Group[0]["INSP_DIV"] == "IB") {
            vds_SearchSpec[0]["HQ_CD"] = vds_Group[0]["MAKER_CD"];
        } else {
            vds_SearchSpec[0]["HQ_CD"] = vds_Group[0]["HQ_CD"];
        }
        vds_SearchSpec[0]["PART_CD"] = vds_Group[0]["PART_CD"];
        vds_SearchSpec[0]["LANG"] = sLocale;
        vds_SearchSpec[0]["DIVISION_CD"] = vds_Group[0]["DIVISION_CD"];

        fn_selectSpecInfo();
    }

    /* Spec Info 조회 */
    function fn_selectSpecInfo() {
        const requestJson: NexacroJsonType = {
            ds_Search: vds_SearchSpec
        };

        sendAxios('/common/nexacro/QMS_P_Pre_InspectBagicRegSelectSpecList.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then(async (response) => {
            vds_Spec = response?.data.ds_Spec;

            let copyDs = [...vds_Spec];

            if (vds_Spec.length > 0) {
                //현재 자재 스펙 승인상태 체크
                if (!await fn_checkSpecApproveStatus(selectedCardDataIdx)) {
                    return;
                }

                for (let i = 0; i < vds_Spec.length; i++) {
                    let samCnt = 0;
                    let inspMth = String(vds_Spec[i]["SEC_INSP_MTH"]);
                    let aql = String(vds_Spec[i]["SEC_AQL_LEVEL"]);

                    //sample cnt 계산 로직
                    if (inspMth != null && inspMth.substr(0, 1) == "A") {// 전수검사일때
                        samCnt = fv_Qty;
                    } else { // 검사방식, AQL 계산
                        samCnt = fn_getSampleCntByMeasMth(inspMth);

                        if (inspMth.substr(0, 1) == "S" || inspMth.substr(0, 1) == "G") {
                            if (aql != null) {
                                samCnt = fn_getSampleCntByAql(aql, samCnt);
                            }
                        }
                    }

                    //VOC 23 그룹판정 샘플수는 실제 입고수를 넘기 않는다.
                    if (fv_groupFlag == "Y") {
                        if (samCnt > parseFloat(String(vds_Group[0]["REAL_GR_QTY"]))) {
                            samCnt = parseFloat(String(vds_Group[0]["REAL_GR_QTY"]));
                        }
                    }

                    //소수점 수량 오류에 따른 변경 by KKA
                    samCnt = Math.ceil(samCnt);

                    copyDs[i]['SAM_CNT'] = String(samCnt);
                }

                vds_Spec = copyDs;

                //ECN 변경점 조회
                let divCd = ds_List[selectedCardDataIdx]["DIVISION_CD"];
                let plantCd = ds_List[selectedCardDataIdx]["PLANT_CD"];
                let hqCd = ds_List[selectedCardDataIdx]["HQ_CD"];
                let makerCd = ds_List[selectedCardDataIdx]["MAKER_CD"]; //2020.10.19 추가 by KKA
                let partCd = ds_List[selectedCardDataIdx]["PART_CD"];

                fn_EcnSearch(divCd, plantCd, makerCd, partCd);   //2020.10.19 수정 by KKA this.fn_EcnSearch(divCd, plantCd, hqCd, partCd);
            } else {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG00939"),
                    confirm: () => {
                    }
                });
            }
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* INV_NO ,로 합치고, GR_QTY 합산, IV_STATUS */
    function fn_setGroupData() {
        let idx = vds_GroupList.findIndex(v => v.INV_NO == ds_List[selectedCardDataIdx]["INV_NO"]);

        vds_Group.push(vds_GroupList[idx]);

        //INV_NO
        let invList = "";

        for (let i = 0; i < vds_GroupList.length; i++) {
            if (i == vds_GroupList.length - 1) {
                invList = invList + vds_GroupList[i]["INV_NO"];
            } else {
                invList = invList + vds_GroupList[i]["INV_NO"] + ",";
            }
        }

        vds_Group[0]["INV_NOS"] = invList;

        //입고수량 합계
        let grQty = 0;
        let realGrQty = 0;

        for (let i = 0; i < vds_GroupList.length; i++) {
            grQty += parseFloat(String(vds_GroupList[i]["GR_QTY"])) * 1000;

            if (vds_GroupList[i]["GR_DATE"] != null && vds_GroupList[i]["GR_DATE"] != '') {
                realGrQty += parseFloat(String(vds_GroupList[i]["GR_QTY"])) * 1000;
            }
        }

        //GR_QTY
        vds_Group[0]["GR_QTY"] = grQty / 1000;
        vds_Group[0]["REAL_GR_QTY"] = realGrQty / 1000;

        //IV_STATUS
        vds_Group[0]["IV_STATUS"] = "1";
        vds_Group[0]["GROUP_YN"] = "Y";

        setDs_Group(vds_Group);
    }

    /* 입고검사 성적서 작성 팝업 */
    function fn_IncmInspRegOpen(row: number = -1) {
        if (row == -1) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG00518'),
                confirm: () => {
                }
            });

            return;
        }

        let dsList = fv_groupFlag == "Y" ? vds_Group : ds_List;

        if (fv_groupFlag != "Y") {
            vds_GroupList = new Array<NexacroJsonUnitType>;
        }

        vds_ListSelect[0] = dsList[fv_groupFlag == "Y" ? 0 : row];
        let tmpYn = "N" // 임시저장 플래그

        if (vds_ListSelect[0]["IV_STATUS"] == "9" && fv_isChangeSpecFlag) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01174"),
                confirm: () => {
                    fn_openInspPopupTempSave();
                }
            });
        } else {
            // 임시저장일때
            if (vds_ListSelect[0]["IV_STATUS"] == "9"){
                tmpYn = "Y";
            }

            let param = new Map();
            param.set("pv_flag", "INS");
            param.set("pv_tmp", tmpYn);
            param.set("pv_data", vds_ListSelect);
            param.set("pv_spec", vds_Spec);
            param.set("pv_defect", vds_Defect);
            param.set("pv_sample", vds_Sample);
            param.set("pv_aql", ds_Aql);
            param.set("pv_allSample", ds_AllSample);
            param.set("pv_ecn", vds_popChngPoingHListFiltered);
            param.set("pv_baseLine", vds_BaseLine);
            param.set("pv_Issue", vds_FailCaseList);
            param.set("pv_GroupList", vds_GroupList);
            param.set("pv_ds_IssuePartList", ds_IssuePartList);
            param.set("pv_GroupYn", fv_groupFlag == "Y" ? "Y" : "N");
            param.set("pv_secAnlResult", secAnlResult);
            param.set("pv_ds_SubmitInspList", ds_SubmitInspList);
            param.set("pv_ds_chngPoingHList", ds_chngPoingHList);

            pageMove(process.env.REACT_APP_URL_QMS_REG || '', {
                params: param
            });
        }

        fv_groupFlag = "N";
    }

    /* 입고검사 성적서 상세보기 팝업 호출 */
    function fn_IncmInspDetailPopOpen(row: number) {
        vds_ListSelect[0] = ds_List[row];

        let param = new Map();
        param.set("pv_flag", "DETAIL_2");
        param.set("pv_data", vds_ListSelect);
        param.set("pv_spec", vds_Spec);
        param.set("v_defect", vds_Defect);

        // QMS_P_Incm_InspReg 성적서 작성 팝업 호출 미사용 QMS::QMS_P_Incm_InspReg.xfdl
    }

    /* 입고검사 성적서 각각 Spec 검색 */
    function fn_selectSpecAndDetail() {
        let row = selectedCardDataIdx;

        let inspResultNo = fv_InspResultNo;
        let tmpYn = "Y";
        let hqCd = "";
        let partCd = "";
        let ischkspeciqc = "";
        let withResultFlag = "";
        let plantCd = ds_List[row]["PLANT_CD"];
        let iv_status = "";

        if (ds_List[row]["IV_STATUS"] == "2" || ds_List[row]["IV_STATUS"] == "4") {
            tmpYn = "N";
            ischkspeciqc = "N";
            iv_status = ds_List[row]["IV_STATUS"];
        } else {
            tmpYn = "Y";

            if (ds_List[row]["INSP_DIV"] == "IS" || ds_List[row]["INSP_DIV"] == "IB") {
                hqCd = ds_List[row]["MAKER_CD"];
            } else {
                hqCd = ds_List[row]["HQ_CD"];
            }

            partCd = ds_List[row]["PART_CD"];
            ischkspeciqc = "Y";
            withResultFlag = "Y";
            fv_isChangeSpecFlag = false;
        }

        const requestJson: NexacroJsonType = {
            ds_SearchDetail: [{
                LANG: sLocale,
                INSP_RESULT_NO: inspResultNo,
                TMP_YN: tmpYn,
                HQ_CD: hqCd,
                PART_CD: partCd,
                ISCHKSPECIQC: ischkspeciqc,
                WITH_RESULT_FLAG: withResultFlag,
                PLANT_CD: plantCd,
                IV_STATUS: iv_status
            }]
        };

        sendAxios('/common/nexacro/QMS_M_PreInspListSelectSpecAndDetail.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            vds_Spec = response?.data.ds_Spec;

            fn_selectDefectDetail();
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* 입고검사 성적서  Defect 검색 */
    function fn_selectDefectDetail() {
        let row = selectedCardDataIdx;

        let inspResultNo = fv_InspResultNo;
        let tmpYn = "Y";

        if (ds_List[row]["IV_STATUS"] == "2" || ds_List[row]["IV_STATUS"] == "4") {
            tmpYn = "N";
        }

        const requestJson: NexacroJsonType = {
            ds_SearchDetail: [{
                LANG: sLocale,
                INSP_RESULT_NO: inspResultNo,
                TMP_YN: tmpYn
            }]
        };

        sendAxios('/common/nexacro/QMS_M_PreInspListSelectDefectDetail.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then(async (response) => {
            vds_Defect = response?.data.ds_Defect;

            if (ds_List[row]["IV_STATUS"] == "2" || ds_List[row]["IV_STATUS"] == "4") {
                fn_IncmInspDetailPopOpen(row);
            } else {
                let divCd = ds_List[row]["DIVISION_CD"];
                let plantCd = ds_List[row]["PLANT_CD"];
                let hqCd = ds_List[row]["HQ_CD"];
                let partCd = ds_List[row]["PART_CD"];

                /* 실시간 Spec 변경이 있을 경우 신규 검사 항목이 추가 되었을 경우 샘플수 계산 */
                if (vds_Spec.length > 0) {
                    //현재 자재 스펙 승인상태 체크
                    if (!await fn_checkSpecApproveStatus(row))
                    {
                        return;
                    }

                    if (vds_Spec[0]["CHANGEFLAG"] == "Y")
                    {
                        fv_isChangeSpecFlag = true;
                    }

                    for (let i = 0; i < vds_Spec.length; i++)
                    {
                        if (vds_Spec[i]["ISCHANGESPEC"] == "Y")
                        {
                            let samCnt = 0;
                            let inspMth = String(vds_Spec[i]["SEC_INSP_MTH"]);
                            let aql = String(vds_Spec[i]["SEC_AQL_LEVEL"]);

                            //sample cnt 계산 로직
                            if (inspMth != null && inspMth.substr(0, 1) == "A")// 전수검사일때
                            {
                                samCnt = fv_Qty;
                            }
                            else // 검사방식, AQL 계산
                            {
                                samCnt = fn_getSampleCntByMeasMth(inspMth);

                                if (inspMth.substr(0, 1) == "S" || inspMth.substr(0, 1) == "G")
                                {
                                    if (aql != null)
                                    {
                                        samCnt = fn_getSampleCntByAql(aql, samCnt);
                                    }
                                }
                            }

                            //소수점 수량 오류에 따른 변경 by KKA
                            samCnt = Math.ceil(samCnt);

                            vds_Spec[i]["SAM_CNT"] =  String(samCnt);
                            vds_Spec[i]["SPEC_ID"] =  vds_Spec[i]["NSPEC_ID"];
                            vds_Spec[i]["SPEC_REV"] =  vds_Spec[i]["NSPEC_REV"];

                            let copyDs = [...vds_Spec];
                            copyDs[0]['SAM_CNT'] = String(samCnt);
                            copyDs[0]['SPEC_ID'] = vds_Spec[i]["NSPEC_ID"];
                            copyDs[0]['SPEC_REV'] = vds_Spec[i]["NSPEC_REV"];
                            vds_Spec = copyDs;

                            for (let k = 0; k < vds_Defect.length; k++)
                            {
                                if (vds_Defect[k]["PARA_CD"] == vds_Spec[i]["PARA_CD"])
                                {
                                    vds_Defect[k]["SPEC_ID"] = vds_Spec[i]["SPEC_ID"];
                                    vds_Defect[k]["SPEC_REV"] = vds_Spec[i]["SPEC_REV"];
                                }
                            }

                            fv_isChangeSpecFlag = true;
                        }
                    }

                    setDs_Defect(vds_Defect);
                }

                fn_EcnSearch(divCd, plantCd, hqCd, partCd);
            }
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    /* 이슈자재 현황 호출 */
    function fn_SearchIssuePart(idx: number) {
        const requestJson: NexacroJsonType = {
            ds_SearchIssue: [{
                MGT_NO: ds_List[idx]['MGT_NO'],
                DIVISION_CD: ds_List[idx]['DIVISION_CD'],
                LANG: sLocale
            }]
        };

        sendAxios('/common/nexacro/QMS_M_Incm_IssusePartDataSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
        .then((response) => {
            if (response?.data.ds_IssuePartList.length > 0) {
                setDs_IssuePartList(response?.data.ds_IssuePartList);
                vds_IssuePartList = response?.data.ds_IssuePartList;
            }
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    async function fn_checkSpecApproveStatus(row: number) {
        //SPEC 승인중인지 체크
        vds_searchSpecApproveStatus = new Array<NexacroJsonUnitType>;

        let vendorCd = ds_List[row]["HQ_CD"];
        let partCd = ds_List[row]["PART_CD"];

        //IPC 의 경우에는 협력사 바꾸어서 조회
        if (ds_List[row]["INSP_DIV"] == "IS" || ds_List[row]["INSP_DIV"] == "IB") {
            vendorCd = ds_List[row]["MAKER_CD"];
        }

        let data = new Array<NexacroJsonUnitType>();
        data.push(
            {
                VENDOR_CD: vendorCd,
                PART_CD: partCd
            }
        );

        vds_searchSpecApproveStatus = data;

        await fn_searchSpecApproveStatus().then((response) => {
            // console.log(response?.data.ds_SpecApproveStatus);
            vds_SpecApproveStatus = response?.data.ds_SpecApproveStatus;
        }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });

        if (vds_SpecApproveStatus.filter(v => String(v.LAST_REV_YN) == "Y" && (String(v.APPROVE_STATUS) == "1" || String(v.APPROVE_STATUS) == "4" || String(v.APPROVE_STATUS) == "5")).length != 1) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01131"),
                confirm: () => {
                }
            });

            return false;
        }

        return true;
    }

    /* 스펙 승인정보 조회 */
    function fn_searchSpecApproveStatus() {
        const requestJson: NexacroJsonType = {
            ds_Part: vds_searchSpecApproveStatus
        };

        return sendAxios('/common/nexacro/selectSpecApproveStatus.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson);
    }

    /* 검사방식 계산 */
    function fn_getSampleCntByMeasMth(measMth: string) {
        let rtnSamCnt = 0;
        vds_AllSample = ds_AllSample.filter(v => v.MEAS_MTH == measMth);

        if (measMth.substr(0, 1)  == "A") { //전수검사 case
            rtnSamCnt = fv_Qty;
        } else if(measMth.substr(0, 1) == "C") {
            rtnSamCnt = Number(vds_AllSample[0]["SAM_CNT"]);

            if (fv_Qty < rtnSamCnt) {
                rtnSamCnt = fv_Qty;
            }
        } else {
            for (let i = 0; i < vds_AllSample.length; i++) {
                let fromSize = Number(vds_AllSample[i]["FROM_SIZE"]);
                let toSize = Number(vds_AllSample[i]["TO_SIZE"]);

                if(fv_Qty >= fromSize && fv_Qty <= toSize){
                    rtnSamCnt = Number(vds_AllSample[i]["SAM_CNT"]);

                    break;
                }
            }
        }

        return rtnSamCnt;
    }

    /* AQL 계산 */
    function fn_getSampleCntByAql(aql: string, samCnt: number) {
        let rtnSamCnt = 0;
        vds_Aql = ds_Aql.filter(v => v.AQL_LEVEL == aql);
        let idx = vds_Aql.findIndex(v => v.AQL_SAMPLE_CNT == samCnt);

        if (idx > -1) {
            let iterator = vds_Aql[idx]["ACCESS_PATH"];
            if (iterator == "U") {
                while(iterator != "OK"){
                    iterator = vds_Aql[idx]["ACCESS_PATH"];
                    rtnSamCnt = Number(vds_Aql[idx]["AQL_SAMPLE_CNT"]);

                    idx--;
                }
            } else if(iterator == "D") {
                while(iterator != "OK") {
                    iterator = vds_Aql[idx]["ACCESS_PATH"];
                    rtnSamCnt = Number(vds_Aql[idx]["AQL_SAMPLE_CNT"]);

                    idx++;
                }
            } else {
                rtnSamCnt = Number(vds_Aql[idx]["AQL_SAMPLE_CNT"]);
            }
            if (fv_Qty < rtnSamCnt) {
                rtnSamCnt = fv_Qty;
            }
        } else {
            rtnSamCnt = samCnt;
        }

        return rtnSamCnt;
    }

    /* fn_openInspPopup */
    function fn_openInspPopup() {
        //작성 팝업 열때, TSI대상이나 미사용 DMR 사용할경우
        if(
            (	ds_List[selectedCardDataIdx]['IV_STATUS'] == "1" //작성
                || ds_List[selectedCardDataIdx]['IV_STATUS'] == "5" //상신취소
                || ds_List[selectedCardDataIdx]['IV_STATUS'] == "9" //임시저장
            ) && vds_ListSelect.length != 0 && vds_ListSelect[0]['TSI_AGENT_YN'] == 'X' // 미사용 DMR 부품
        ){
            //TSI 기능을 제공하지 않는 미사용 DMR 입니다. Part DMR을 표준 DMR로 변경하시기 바랍니다.
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01412"),
                confirm: () => {
                    fn_confirmIncmInspRegOpen();
                }
            });
        } else {
            fn_IncmInspRegOpenProcess();
        }
    }

    function fn_confirmIncmInspRegOpen() {
        fn_IncmInspRegOpenProcess();
    }

    function fn_IncmInspRegOpenProcess() {
        let row = selectedCardDataIdx;

        //IV_STATUS 1.입고대기, 2.판정완료, 3.전송완료, 4.상신중, 5.상신취소, 9.임시저장
        if (ds_List[row]['IV_STATUS'] == "1") {
            /** VOC 35 Start : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/
            /** 다시 조회한 데이터의 DMR Stage 가 Skip이 아닌 다른 DMR Stage로 변경될 경우 Dataset 변경 **/
            if (ds_List[row]['DMR_GROUP'] != vds_InspDetail[0]['DMR_GROUP'])
            {
                // ds_List.copyRow(row, this.ds_InspDetail,0);

                // let copyDs: NexacroJsonUnitType = ds_List[row];
                // copyDs = ds_InspDetail[0];
                // setDs_List([...ds_List]);

                let copyDs = [...ds_List]; // copy보람보람보람
                let copyDs2: NexacroJsonUnitType = copyDs[row];
                copyDs2 = vds_InspDetail[0];
                setDs_List(copyDs);

                // let copyDs = [...ds_List[row]];
                // copyDs = ds_InspDetail[0];
                // setDs_List([...copyDs, ds_InspDetail[0]]);

            }

            /** VOC 35 End   : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/
            if (fn_isChangeLotInfo(row)) {
                // ds_List.copyRow(row, this.ds_InspDetail,0);

                let copyDs: NexacroJsonUnitType = ds_List[row]; // copy보람보람보람
                copyDs = vds_InspDetail[0];
                setDs_List([...ds_List]);
            }

            // 2019.01.07 무선 사외검사 성적서 작성 CASE 대응을 위해 입고일 없어도 성적서 OPEN 가능하도록 수정
            if ((ds_List[row]['INSP_STATUS'] == "N" || ds_List[row]['INSP_STATUS'] == "S") && ds_List[row]['MGT_NO'] == undefined) {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG01044"),
                    confirm: () => {
                    }
                });

                return;
            }

            if (vds_SearchSpec.length == 0) {
                vds_SearchSpec.push({
                    LOT_SIZE: ds_List[row]['GR_QTY'],
                    PLANT_CD: ds_List[row]['PLANT_CD']
                });
            } else {
                vds_SearchSpec[0]['LOT_SIZE'] = ds_List[row]['GR_QTY'];
                vds_SearchSpec[0]['PLANT_CD'] = ds_List[row]['PLANT_CD'];
            }

            //IPC 의 경우에는 협력사 바꾸어서 조회
            if (ds_List[row]['INSP_DIV'] == "IS" || ds_List[row]['INSP_DIV'] == "IB") {
                vds_SearchSpec[0]['HQ_CD'] = ds_List[row]['MAKER_CD'];
            } else {
                vds_SearchSpec[0]['HQ_CD'] = ds_List[row]['HQ_CD'];
            }

            vds_SearchSpec[0]['PART_CD'] = ds_List[row]['PART_CD'];
            vds_SearchSpec[0]['LANG'] = sLocale;
            vds_SearchSpec[0]['DIVISION_CD'] = ds_List[row]['DIVISION_CD'];

            fn_selectSpecInfo();
        } else if(ds_List[row]['IV_STATUS'] == "9" || ds_List[row]['IV_STATUS'] == "5") {	// 임시저장이거나 상신취소일때
            /** VOC 35 Start : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/
            /** 다시 조회한 데이터의 DMR Stage 가 Skip이 아닌 다른 DMR Stage로 변경될 경우 Dataset 변경 **/
            if (ds_List[row]['DMR_GROUP'] != vds_InspDetail[0]['DMR_GROUP'])
            {
                let copyDs: NexacroJsonUnitType = ds_List[row]; // copy보람보람보람
                copyDs = vds_InspDetail[0];
                setDs_List([...ds_List]);
            }
            /** VOC 35 End   : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/

            if (fn_isChangeLotInfo(row)) {
                let copyDs: NexacroJsonUnitType = ds_List[row]; // copy보람보람보람
                copyDs = vds_InspDetail[0];
                setDs_List([...ds_List]);
            }

            /** VOC 35 Start : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/
            /** 임시저장 이후 검사대상이 Skip으로 바뀔경우 팝업 제한 **/
            if ((ds_List[row]['INSP_STATUS'] == "N" || ds_List[row]['INSP_STATUS'] == "S") && ds_List[row]['MGT_NO'] == undefined) {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG01044"),
                    confirm: () => {
                    }
                });

                return;
            }
            /** VOC 35 End   : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/

            fv_InspResultNo = ds_List[row]['INSP_RESULT_NO_TMP'];

            fn_selectSpecAndDetail();
        } else if (ds_List[row]['IV_STATUS'] == "2" || ds_List[row]['IV_STATUS'] == "4" ){
            fv_InspResultNo = ds_List[row]['INSP_RESULT_NO_TMP'];

            fn_selectSpecAndDetail();
        }
    }

    /* 성적서 작성 버튼 클릭 시 (SQCI3.0 넥사크로 버젼 셀더블클릭시) */
    async function grd_List_oncelldblclick(selectedCardDataIdx: number) {
        if (selectedCardDataIdx == -1) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG00518'),
                confirm: () => {
                }
            });

            return;
        }

        fv_Qty = ds_List[selectedCardDataIdx]['GR_QTY'];

        /*VOC 23 그룹판정 시 상세화면 열기*/
        if (ds_List[selectedCardDataIdx]['IV_STATUS'] == "2" || ds_List[selectedCardDataIdx]['IV_STATUS'] == "4") {
            if (!gfn_isNull(ds_List[selectedCardDataIdx]['INSP_RESULT_NO_TMP'])) {
                fn_openInspPopup();
            }
        } else {
            /** VOC 18 Start : 2019.05.28 임시저장된 입고성적서와 변경된 출하성적서 자재구분 매칭**/
            // 현재 입고 대상에 출하성적서 제출 매핑정보 조회
            vds_SearchInspDetail = [];

            let data = new Array<NexacroJsonUnitType>();
            data.push(
                {
                    LANG: sLocale,
                    HQ_CD: ds_List[selectedCardDataIdx]['HQ_CD'],
                    PART_CD: ds_List[selectedCardDataIdx]['PART_CD'],
                    PLANT_CD: ds_List[selectedCardDataIdx]['PLANT_CD'],
                    INV_NO: ds_List[selectedCardDataIdx]['INV_NO'],
                    SQCI_LOT_NO: ds_List[selectedCardDataIdx]['SQCI_LOT_NO']
                }
            );

            vds_SearchInspDetail = data;

            await fn_selectIncmDetail().then((response) => {
                vds_InspDetail = response?.data.ds_InspDetail;
            }).catch(e => {
                throwException(e);
                return new Promise(() => {
                });
            });

            /** VOC 35 Start : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/
            /** 다시 조회한 데이터의 검사 대상이 N 일 경우(skip) 팝업 제한 **/
            if (ds_List[selectedCardDataIdx]['INSP_STATUS'] != "N" && vds_InspDetail[0]['INSP_STATUS'] == "N")
            {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG01173"),
                    confirm: () => {
                    }
                });

                fn_tranSearch();

                return;
            }

            /** 다시 조회한 데이터의 DMR Stage 가 Skip이 아닌 다른 DMR Stage로 변경될 경우 알림후 진행 **/
            if (ds_List[selectedCardDataIdx]['DMR_GROUP'] != vds_InspDetail[0]['DMR_GROUP'])
            {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG01173"),
                    confirm: () => {
                        fn_openInspPopup();
                    }
                });

                return;
            }
            /** VOC 35 End   : 2019.05.30 채보람 임시저장 성적서 DMR Status 변경시 반영 **/

            //조회후 상태 비교 하여 바뀌면 바뀐정보로 매핑 해야한다.
            //제출정보 변경시 사용자에게 알림후 데이터 매핑한다.
            if (fn_isChangeLotInfo(selectedCardDataIdx)) {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG01166"),
                    confirm: () => {
                        fn_openInspPopup();
                    }
                });
            } else {
                fn_openInspPopup();
            }
            /** VOC 18 End   : 2019.05.28 임시저장된 입고성적서와 변경된 출하성적서 자재구분 매칭 **/

        }
    }

    /* 성적서 제출 내용 체크 */
    function fn_isChangeLotInfo(row: number) {
        if (ds_List[row]['LOT_ID'] != vds_InspDetail[0]['LOT_ID']) {
            return true;
        }

        if (ds_List[row]['SUB_STATUS'] != vds_InspDetail[0]['SUB_STATUS']) {
            return true;
        }

        if (ds_List[row]['GR_DATE'] != vds_InspDetail[0]['GR_DATE']) {
            return true;
        }

        if (ds_List[row]['GR_TIME'] != vds_InspDetail[0]['GR_TIME']) {
            return true;
        }

        if (parseInt(ds_List[row]['SUBMIT_CNT']) != parseInt(String(vds_InspDetail[0]['SUBMIT_CNT']))) {
            return true;
        }

        return false;
    }

    /* 임시저장된 데이터의 Spec 정보가 변경되었을 경우 alert 메시지 호출 */
    function fn_openInspPopupTempSave() {
        let param = new Map();
        param.set("pv_flag", "INS");
        param.set("pv_tmp", "Y");
        param.set("pv_data", vds_ListSelect);
        param.set("pv_spec", vds_Spec);
        param.set("pv_defect", vds_Defect);
        param.set("pv_sample", vds_Sample);
        param.set("pv_aql", vds_Aql);
        param.set("pv_allSample", vds_AllSample);
        param.set("pv_ecn", vds_popChngPoingHListFiltered);
        param.set("pv_baseLine", vds_BaseLine);
        param.set("pv_Issue", vds_FailCaseList);
        param.set("pv_ds_IssuePartList", ds_IssuePartList);

        pageMove(process.env.REACT_APP_URL_QMS_REG || '', {
            params: param
        });
    }

    /* 성적서 저장 완료 후 호출 */
    function fn_popCallback(popId: string, sVal: string) {
        switch(popId) {
            case "QMS_P_Incm_InspReg":	//저장 완료시
                if (fv_refreshFlag == "Y" || sVal == "S") {
                    fn_tranSearch();

                    fv_refreshFlag = "N";	// 다시 초기화
                }

                break;
            case "QMS_P_Incm_InspGroupList" :
                break;
        }
    }

    /* 목록 판정 가능 여부 체크 */
    function checkLineSaveResult(idx: number) {
        if (!(ds_List[idx]['DIVISION_CD'] != "C"
            && ds_List[idx]['PLANT_CD'] != "P517"
            && ds_List[idx]['PLANT_CD'] != "P518"
            && ds_List[idx]['PLANT_CD'] != "P507"
            && ds_List[idx]['PLANT_CD'] != "P508"
            && ds_List[idx]['PLANT_CD'] != "P509"
            && ds_List[idx]['PLANT_CD'] != "P510"
            && ds_List[idx]['PLANT_CD'] != "P550"
            && ds_List[idx]['PLANT_CD'] != "P171"
            && ds_List[idx]['PLANT_CD'] != "P172"
            && ds_List[idx]['PLANT_CD'] != "P173"
            && ds_List[idx]['PLANT_CD'] != "P174"
            && ds_List[idx]['IV_STATUS'] != "2"
            && ds_List[idx]['IV_STATUS'] != "9"
            && ds_List[idx]['TSI_AGENT_YN'] != "Y"))
        {
            setLineSaveDisabled(true);

            return false;
        }

        setLineSaveDisabled(false);

        return true;
    }

    /* 목록 판정 클릭 시 */
    function fn_saveResult() {
        if (selectedCardDataIdx == -1) {
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage('MSG00518'),
                confirm: () => {
                }
            });

            return;
        }

        /* 목록 판정 가능 여부 체크 */
        if (!checkLineSaveResult(selectedCardDataIdx)) {
            return;
        }

        setDs_ListLineMeas(ds_List[selectedCardDataIdx]);

        goTranLineMeas(selectedCardDataIdx);
    }

    /* 날짜 계산 */
    function getToday(d: number = 0, m: number = 0, y: number = 0){
        let date = new Date();

        date.setFullYear(date.getFullYear() + y);
        date.setMonth(date.getMonth() + m);
        date.setDate(date.getDate() + d);

        let year = date.getFullYear();
        let month = ("0" + (1 + date.getMonth())).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);

        return year + month + day;
    }

    /* 카드 선택 시 */
    const selectedCard = (card?: CardViewType[]) => {
        if (card == undefined || card.length == 0) {
            setSelectedCardDataIdx(-1);

            return;
        }

        let uuid = card && card.length > 0 ? card[0].id : '';
        //2025-02-11 zilong.ge PAD SQCI PSIS Code 错乱问题反馈
        let id = String(infiniteScroll.findIndex((item, index) => item.id === uuid));

        if(id !== '') {
            setSelectedCardDataIdx(Number(id));

            setSecAnlResult(ds_List[Number(id)]['SEC_ANL_RESULT']); // 협력사 성적서

            /* 이슈 자재 조회 */
            if (ds_List[Number(id)]['MGT_NO'] != undefined || ds_List[Number(id)]['MGT_NO'] != null || ds_List[Number(id)]['MGT_NO'] != '') {
                fn_SearchIssuePart(Number(id));
            }

            fn_SearchSubmitInsp(Number(id));
        }

        /* 목록 판정 가능 여부 체크 */
        checkLineSaveResult(Number(id));
    };

    /* 제출된 성적서 현황 호출 */
    function fn_SearchSubmitInsp(idx: number) {
        const requestJson: NexacroJsonType = {
            ds_SearchInsp: [{
                HQ_CD: ds_List[idx]["HQ_CD"],
                PART_CD: ds_List[idx]["PART_CD"],
                INV_NO: ds_List[idx]["INV_NO"]
            }]
        };

        sendAxios('/common/nexacro/QMS_M_Incm_SubmitInspDataSelect.do', 'POST', AXIOS_HEADER.NEXACRO, requestJson)
            .then((response) => {
                setDs_SubmitInspList(response?.data.ds_SubmitInspList);
            }).catch(e => {
            throwException(e);
            return new Promise(() => {
            });
        });
    }

    const getData = async (start: number, end: number) => {
        let temp_ds_Search: NexacroJsonUnitType[] = state.requestJson.ds_Search;
        temp_ds_Search[0]['RECORDS'] = String(end);
        temp_ds_Search[0]['RECORDSOFFSET'] = String(start);

        if(gfn.gfn_isNull(temp_ds_Search)){
            setDialog({
                type: 'ALERT',
                show: true,
                text: getMessage("MSG01526"), /*"올바르지 않은 경로입니다."*/
                confirm: () => {
                    pageMove(-1);
                }
            });
            // pageMove(process.env.REACT_APP_URL_QMS || '')
            // pageMove(-1);
            return[];
        }

        // return infiniteScroll.slice(start, end);
        return await sendAxios('/common/nexacro/QMS_M_Incm_InspListDataSelect.do', 'POST', AXIOS_HEADER.NEXACRO, state.requestJson)
            .then(response => {
            let temp_ds_List:NexacroJsonUnitType[] = response?.data.ds_List||[];

            if(temp_ds_List.length == 0 && start === 1){
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage('MSG00930'), //검색결과가 없습니다.
                    confirm: () => {
                        pageMove(-1);
                    }
                });
                // pageMove(process.env.REACT_APP_URL_QMS || '');
                // pageMove(-1);
                return [];
            }

            let dmrPass = false;

            for (let i = 0; i < temp_ds_List.length; i++) {
                if (!((temp_ds_List[i]['INSP_STATUS'] == "N" || temp_ds_List[i]['INSP_STATUS'] == "S") && temp_ds_List[i]['MGT_NO'] == undefined)) {
                    dmrPass = true;

                    break;
                }
            }

            if (start === 1 && !dmrPass && temp_ds_Search[0]['INV_NO'] != '') {
                setDialog({
                    type: 'ALERT',
                    show: true,
                    text: getMessage("MSG01044"),
                    confirm: () => {
                        pageMove(-1);
                    }
                });

                // pageMove(process.env.REACT_APP_URL_QMS || '');
                // pageMove(-1);
                return [];
            }

            let copyArray = [...ds_List]||[];
            let responseDsList = [...response?.data.ds_List]||[];
            setDs_List([
                ...copyArray ,
                ...responseDsList
            ]);

            let cardL:CardViewType[] = (temp_ds_List||[]).map((tempList:NexacroJsonUnitType, i:number) => {
                /* status 색상 */
                let ivStatusStyle = 'label-type1';

                if (tempList.IV_STATUS == '1') { // 검사대기
                    ivStatusStyle = 'label-type8';
                } else if (tempList.IV_STATUS == '9') { // 검사진행중
                    ivStatusStyle = 'label-type6';
                } else if (tempList.IV_STATUS == '2') { // 검사완료
                    ivStatusStyle = 'label-type10';
                } else if (tempList.IV_STATUS == '4') { // 상신중
                    ivStatusStyle = 'label-type11';
                } else if (tempList.IV_STATUS == '5') { // 상신취소
                    ivStatusStyle = 'label-type12';
                }

                if (tempList.IV_STATUS != '3' && tempList.SMART_INSP_STATUS == 'H') { // Hold
                    ivStatusStyle = 'label-type17';
                }

                /* 자재구분 */
                let newPartTypeStyle = 'label-type3';

                if (tempList.NEW_PART_TYPE == 'O') { // 양산자재
                    newPartTypeStyle = 'label-type8';
                } else if (tempList.NEW_PART_TYPE == 'N') { // 신규초물
                    newPartTypeStyle = 'label-type2';
                } else if (tempList.NEW_PART_TYPE == 'C') { // 변경초물
                    newPartTypeStyle = 'label-type2';
                }

                /* 성적서 제출상태 */
                let subStatusStyle = 'label-type2';

                if (tempList.SUB_STATUS == 'Y') { // 협력사성적서 제출완료
                    subStatusStyle = 'label-type13';
                } else if (tempList.SUB_STATUS == 'S') { // 미제출 + 미완료
                    subStatusStyle = 'label-type14';
                } else if (tempList.SUB_STATUS == 'N') { // 협력사성적서 미제출
                    subStatusStyle = 'label-type14';
                } else if (tempList.SUB_STATUS == 'U') { // 협력사성적서 비해당
                    subStatusStyle = 'label-type15';
                } else if (tempList.SUB_STATUS == 'T') { // 협력사성적서 SMARTQ IF정보 임시저장
                    subStatusStyle = 'label-type16';
                } else if (tempList.SUB_STATUS == 'P') { // 협력사성적서 부분제출
                    subStatusStyle = 'label-type14';
                } else if (tempList.SUB_STATUS == 'C') { // 협력사성적서 제출불가
                    subStatusStyle = 'label-type7';
                }

                let cardD:CardViewType = {
                    id: uuidv4(),
                    label: [{
                        name: String(tempList.IV_STATUS_NM),
                        style: ivStatusStyle
                    }, {
                        name: String(tempList.NEW_PART_TYPE_NM),
                        style: newPartTypeStyle
                    }, {
                        name: String(tempList.SUB_STATUS_NM),
                        style: subStatusStyle
                    }],
                    title: {
                        category: "[" + tempList.PLANT_CD + "] [" + tempList.HQ_CD + "] [" + tempList.PART_CD + "]",
                        name: String(tempList.PART_NM)
                    },
                    state: [{
                        left: 'I/V No : ' + tempList.INV_NO,
                        right: String(tempList.SUP_RECEIVE_DATE)
                    }, {
                        left: 'Insp No : ' + (tempList.INSP_LOT_NO ? tempList.INSP_LOT_NO : ''),
                        right: gfn.gfn_isNull(tempList.GR_DATE) ? '' : String(tempList.GR_DATE)
                    }, {
                        left: getDomain('DOMAIN2415') + ' : ' + gfn.fn_comma(tempList.GR_QTY),
                        right: String(gfn.gfn_isNull(tempList.INSP_USER) ? '' : tempList.INSP_USER)
                    }],
                    extensionData: "DMR : " + String(tempList.DMR_DETAIL),
                    useSelect: true
                }
                return cardD;
            });
            setInfiniteScroll([
                ...infiniteScroll || [],
                ...cardL
            ]);
            return cardL;
        }).catch(e => {
            return [{
                id: '',
                label: [{
                    name: '',
                    style: ''
                }],
                title: {
                    category: '',
                    name: ''
                },
                state: [{
                    left: '',
                    right: ''
                }]
            }];
        });
    };

    /* 카드 x 버튼 클릭 시 */
    const closedCard = (card: CardViewType) => {

    };

    /* 목록 판정 열기 */
    const goTranLineMeas = (index: number) => {
        setShowSwiperLineMeas(true);
    };

    /* 목록 판정 닫기 */
    const closeTranLineMeas = () => {
        setShowSwiperLineMeas(false);

        fn_tranSearch(); // 목록 조회
    };

    /* 그룹 판정 열기 */
    const goShowSwiperInspGroup = (index: number) => {
        setShowSwiperInspGroup(true);
    };

    /* 그룹 판정 닫기 */
    const closeShowSwiperInspGroup = () => {
        setShowSwiperInspGroup(false);
    };

    /* 그룹 판정 선택완료 */
    const inspGroupPopup = (selectedLotList: any) => {
        setShowSwiperInspGroup(false);

        if (selectedLotList == undefined || selectedLotList == "") {
            return;
        }

        fv_groupFlag = "Y";

        setDs_GroupList(selectedLotList);
        vds_GroupList = [...selectedLotList];

        fn_openInspGroupPopup();
    };

    return (
        <>
            <div className={'container'}>
                {
                    isComponentLoaded ? <InfiniteCardView
                        listCount={10}
                        getData={(start: number, end: number) => getData(start, end)}
                        multiSelection={false}
                        onSelect={selectedCard}
                        onClose={undefined}
                    /> : null
                }
                <div className={'btn-area bottom num3'}>
                    <button type={'button'} className={'button-line'} style={{lineHeight:'1.5rem'}} onClick={() => fn_saveGroup()} dangerouslySetInnerHTML={{__html: getDomain('DOMAIN4639')||''}}></button>  {/*그룹판정*/}
                    <button type={'button'} className={'button-line'} style={{lineHeight:'1.5rem'}} disabled={lineSaveDisabled} onClick={() => fn_saveResult()} dangerouslySetInnerHTML={{__html: getDomain('DOMAIN4640')||''}}></button>  {/*즉시판정*/}
                    <button type={'button'} className={'button-primary'} style={{lineHeight:'1.5rem'}} onClick={() => grd_List_oncelldblclick(selectedCardDataIdx)} dangerouslySetInnerHTML={{__html: getDomain('DOMAIN4641')||''}}></button>  {/*성적서 작성*/}
                </div>
            </div>
            <CSSTransition in={showSwiperLineMeas} classNames={showSwiperLineMeas ? 'right' : 'left'} timeout={500} unmountOnExit>
                <QmsTranLineMeas ds_ListLineMeas={ds_ListLineMeas}
                                 onClose={closeTranLineMeas}
                                 selected={selectedCardDataIdx}
                />
            </CSSTransition>
            <CSSTransition in={showSwiperInspGroup} classNames={showSwiperInspGroup ? 'right' : 'left'} timeout={500} unmountOnExit>
                <QmsInspGroup ds_ListSelect={ds_List[selectedCardDataIdx]}
                              onClose={(selectedLotList: any) => inspGroupPopup(selectedLotList)}
                />
            </CSSTransition>
        </>
    );
};